import React from "react";
import ServiceCard from "./servicecard";
import { motion } from "framer-motion";
import linecircle from "../../assets/images/aboutcircle.png";
import dex from "../../assets/logo/Dex.png";
import staking from "../../assets/logo/staking.png";
import multichain from "../../assets/logo/multichain.png";
import defi from "../../assets/logo/defi.png";
import nft from "../../assets/logo/nfts.png";
import marketplace from "../../assets/logo/marketplace.png";
import frame1 from "../../assets/images/frame1.png";

const OurServices = () => {
  const infos = [
    {
      id: 1,
      icon: `${dex}`,
      title: "Decentralized Exchange (DEX) ",
      text: "Seamless trading platforms for secure and efficient token swaps.",
    },
    {
      id: 2,
      icon: `${multichain}`,
      title: "Multi Chain Bridge",
      text: "Cross-chain asset transfers to unify blockchain ecosystems.",
    },
    {
      id: 3,
      icon: `${staking}`,
      title: "Staking Platform",
      text: "Scalable staking solutions that reward user participation.",
    },
    {
      id: 4,
      icon: `${marketplace}`,
      title: "NFT Marketplace",
      text: "Custom marketplaces for trading unique digital assets.",
    },
    {
      id: 5,
      icon: `${nft}`,
      title: "NFTs & 3D Design/Animation",
      text: "Captivating NFT designs and 3D animations to bring digital art to life.",
    },
    {
      id: 6,
      icon: `${defi}`,
      title: "Defi Lending",
      text: "Secure decentralized lending platforms for frictionless finance.",
    },
  ];
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <div
      id="ourservices"
      className=" relative flex flex-col items-center justify-center h-auto w-full  font-poppins bg-chacoblack "
    >
      <img
        className=" z-0 absolute hidden md:inline-flex right-0 bottom-0 rotate-180 h-[50%] md:h-[60%] lg:h-[80%] "
        src={frame1}
        alt="leftframe"
      />
      <div className=" z-20 relative flex flex-col items-center w-[90%] md:w-[700px] lg:w-[1200px]  py-[120px] md:py-[140px] lg:py-[100px] ">
        <img
          className=" absolute left-[-70px] top-[40px] h-[300px] md:h-[350px] "
          src={linecircle}
          alt="backgroundImage"
        />{" "}
        <div className=" flex flex-col items-center text-black font-light ">
          <h2 className=" text-center text-xl leading-snug lg:leading-[55px] text-white  font-medium  font-spaceGrotesk ">
            Our <span className=" text-green ">Services</span>
          </h2>
          <p className=" text-center text-offwhite text-[16px] font-outfit mt-[4px] ">
            End-To-End WEB3 Product Design, Development & Deployment
          </p>

          <div className=" flex flex-col items-center w-[80%] md:w-[700px] lg:w-auto py-[40px] ">
            <motion.div
              variants={container}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.1 }}
              className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[20px] md:gap-[30px] "
            >
              {infos
                ? infos.map((info) => {
                    return (
                      <div key={info.id}>
                        <ServiceCard info={info} />
                      </div>
                    );
                  })
                : ""}
            </motion.div>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default OurServices;
