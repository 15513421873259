import React from "react";
import image from "../../assets/images/aboutImage.png";
import linecircle from "../../assets/images/aboutcircle.png";
import light from "../../assets/images/light1.png";

const AboutUs = () => {
  return (
    <div
      id="about"
      className=" relative flex flex-col items-center justify-center h-auto w-full font-poppins overflow-hidden bg-chacoblack"
    >
      <div className=" z-20 flex flex-col lg:flex-row items-center w-[90%] md:w-[700px] lg:w-[1200px]  py-[120px] md:py-[140px] lg:py-[100px] ">
        <div className=" w-full flex flex-col text-offwhite font-light ">
          <h1 className=" text-xl font-medium font-spaceGrotesk  ">
            About <span className=" text-green ">Us</span>
          </h1>
          <p className=" text-[16px] font-outfit mt-[6px]  ">
            We are a product as well as service-based , company{" "}
            <br className=" hidden lg:inline-flex " /> energetic and driven to
            be the best in We are <br className=" hidden lg:inline-flex " />{" "}
            committed to Blockchain Technology and Blockchain.{" "}
            <br className=" hidden lg:inline-flex " /> using its versatility in
            creating innovative solutions for{" "}
            <br className=" hidden lg:inline-flex " /> real-world challenges. We
            aim to harness its potential{" "}
            <br className=" hidden lg:inline-flex " />
            in offering indispensable interoperability within{" "}
            <br className=" hidden lg:inline-flex " /> various industries.
          </p>
        </div>
        <div className=" relative w-full flex items-center ">
          <img
            className=" z-20 h-[300px] md:h-[450px] "
            src={image}
            alt="backgroundImage"
          />{" "}
          <img
            className=" hidden lg:inline-flex absolute h-[410px] top-[40px] right-[80px] "
            src={linecircle}
            alt="backgroundImage"
          />{" "}
        </div>
      </div>
      <img
        className=" absolute z-20 left-0 bottom-[-150px] h-[300px] md:h-[550px] "
        src={light}
        alt="backgroundImage"
      />{" "}
    </div>
  );
};

export default AboutUs;
