import React from "react";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-12">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-md p-8 mt-[40px] ">
          <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
          <p className="mb-6">
            This is the privacy policy for oXquantum. It explains how we
            collect, use, and protect your personal information when you use our
            website or services.
          </p>
          <h2 className="text-xl font-bold mb-4">Information We Collect</h2>
          <p className="mb-6">
            We may collect the following types of personal information: such as
            name, email, address.
          </p>
          <h2 className="text-xl font-bold mb-4">
            How We Use Your Information
          </h2>
          <p className="mb-6">
            We use your personal information for the following purposes: such as
            to provide services, improve user experience.
          </p>
          <h2 className="text-xl font-bold mb-4">Information Sharing</h2>
          <p className="mb-6">
            We may share your personal information with third parties in the
            following circumstances: such as with service providers, legal
            obligations.
          </p>
          <h2 className="text-xl font-bold mb-4">Data Security</h2>
          <p className="mb-6">
            We take the security of your personal information seriously and
            implement appropriate measures to protect it. such as encryption,
            secure protocols.
          </p>
          <h2 className="text-xl font-bold mb-4">Your Rights</h2>
          <p className="mb-6">
            You have certain rights regarding your personal information, such as
            the right to access, update, or delete it.
          </p>
          <h2 className="text-xl font-bold mb-4">Changes to this Policy</h2>
          <p className="mb-6">
            We may update this privacy policy from time to time. Any changes
            will be posted on this page with a revised date.
          </p>
          <p>
            If you have any questions or concerns about our privacy policy,
            please contact us at{" "}
            <a
              className="text-blue-500 hover:text-blue-300"
              href="mailto:infoOxquantum@gmail.com"
            >
              infoOxquantum@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
