import React from "react";
import { motion } from "framer-motion";
import "react-multi-carousel/lib/styles.css";
import one from "../../assets/images/one.png";
import two from "../../assets/images/two.png";
import three from "../../assets/images/three.png";
import design from "../../assets/images/design.png";
import consulting from "../../assets/images/consulting.png";
import development from "../../assets/images/development.png";
import WorkProcessCard from "./workprocesscard";
import frame3 from "../../assets/images/frame3.png";
import vector5 from "../../assets/images/vector5.png";

const WorkProcess = () => {
  const workProcess = [
    {
      id: 1,
      icon: `${one}`,
      image: `${consulting}`,
      title: "Project Outline",
      description:
        "We begin by engaging in extensive discussions with our clients to understand their specific needs and desired features for the project.",
    },
    {
      id: 2,
      icon: `${two}`,
      image: `${design}`,
      title: "Design Phase",
      description:
        "Detailed system components and mechanisms are meticulously outlined. Visual prototypes are created, and logic for the project/smart contract is defined, including the structure and conditions.",
    },
    {
      id: 3,
      icon: `${three}`,
      image: `${development}`,
      title: "Development Phase",
      description:
        "Our expert team develops smart contracts/project and writes thorough unit tests. These contracts/project beta are then deployed for comprehensive testing and validation.",
    },
  ];

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <div
      id="platforms"
      className=" relative flex flex-col items-center justify-center h-auto w-full  font-poppins bg-chacoblack "
    >
      <div className=" z-20 flex flex-col items-center w-[90%] md:w-[700px] lg:w-[1200px] my-[120px] md:my-[140px] lg:my-[100px] ">
        <div className=" flex flex-col items-center w-full text-white font-light ">
          <h2 className=" text-center text-xl leading-snug lg:leading-[55px] font-spaceGrotesk text-white font-medium  ">
            Work <span className=" text-green ">Process</span>
          </h2>
          <div className=" z-10 relative h-auto w-full mt-[30px] md:mt-[30px]  ">
            {workProcess?.map((process) => {
              return (
                <motion.div
                  variants={container}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.1 }}
                  key={process.id}
                  className=" flex flex-col items-center "
                >
                  <WorkProcessCard process={process} />
                </motion.div>
              );
            })}
          </div>
          <div className=" relative flex flex-col md:flex-row items-center justify-between w-full lg:w-[80%] rounded-[16px] bg-gradient-to-tr from-green via-green to-green/70 p-[30px] md:p-[50px] mt-[60px] overflow-hidden ">
            <h1 className="z-20 text-chacoblack text-[30px] md:text-[50px] font-spaceGrotesk font-semibold ">
              Work with Us
            </h1>
            <a
              href="#footer"
              className=" grid place-items-center z-20 h-[35px] w-[120px] bg-chacoblack text-xs text-green font-medium font-PlusJakartaSans mt-[10px] "
            >
              Say Hi
            </a>
            <img
              className=" z-0 absolute hidden md:inline-flex left-0 bottom-0 top-[-50px] h-[50%] md:h-[60%] lg:h-[250px] "
              src={frame3}
              alt="leftframe"
            />
            <img
              className=" z-0 absolute right-0 bottom-0 top-0 h-full md:h-full "
              src={vector5}
              alt="leftframe"
            />
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default WorkProcess;
