import React from "react";
import { motion } from "framer-motion";
import { AiFillStar } from "react-icons/ai";

const TestimonialsCard = ({ testimonial }: any) => {
  const item = {
    hidden: { x: 20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
    },
  };

  return (
    <motion.div
      variants={item}
      className=" flex flex-col md:flex-row items-center justify-between h-auto md:h-[300px] lg:h-[300px] w-[350px] md:w-[580px] lg:w-[580px] bg-[#1F1F22] rounded-[10px] p-[30px] "
    >
      <div
        style={{ backgroundImage: `url(${testimonial?.projectLogo})` }}
        className=" h-[80px] w-[80px] rounded-[10px] bg-center bg-contain bg-no-repeat "
      >
        {/* <img src={maxxchainlogo} alt="log" /> */}
      </div>
      <div className=" w-[250px] md:w-[78%] flex flex-col items-start text-xs text-offwhite font-outfit mt-[18px] md:mt-0 ">
        <div className=" flex items-center text-yellow-300 ">
          {[...Array(testimonial.starCount)].map((s, index) => (
            <div className=" mr-[3px] " key={index}>
              <AiFillStar fontSize="large" />
            </div>
          ))}
        </div>
        <p className=" text-xs leading-5 mt-[10px] text-offwhite/70 ">
          {testimonial?.description}
        </p>
        <p className=" mt-[10px] font-light ">
          <span className=" text-white font-medium font-spaceGrotesk ">
            {testimonial?.name}
          </span>{" "}
          {testimonial?.project}
        </p>
      </div>
    </motion.div>
  );
};

export default TestimonialsCard;
