import React from "react";
import { motion } from "framer-motion";
import frame1 from "../../assets/images/frame1.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ProjectCard from "./latestprojectcard";

const LatestProjects = () => {
  const images = [
    {
      id: 1,
      name: "Legend Airdrop Bot",
      link: "https://t.me/LegendsAirDropBot",
      desc: `Legend Airdrop bot features a tap game with daily leadership
       board and referral system for telegram tap game lovers.`,
      image: `https://i.ibb.co/2K2C3gs/photo-2024-09-03-4-43-06-PM.jpg`,
    },
    {
      id: 2,
      name: "MaxxChain bridge",
      link: "https://wizardbridge.org/",
      desc: `The Maxxchain bridge creates a securd interoperability between Maxxchain and 
      Basechain enabling project owners to move erc20 tokens in and out of the both chains.`,
      image: `https://i.ibb.co/cNhNRpH/Screenshot-2024-09-03-at-5-36-07-PM.png`,
    },
  ];

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1180 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1180, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 425, min: 0 },
      items: 1,
    },
  };

  return (
    <div
      id="portfolio"
      className=" relative flex flex-col items-center justify-center h-auto w-full  font-poppins bg-chacoblack "
    >
      <img
        className=" z-0 absolute hidden md:inline-flex left-0 bottom-0 h-[50%] md:h-[60%] lg:h-full "
        src={frame1}
        alt="leftframe"
      />
      <div className=" z-20 flex flex-col items-center w-[90%] md:w-[700px] lg:w-[1200px] my-[120px] md:my-[140px] lg:my-[100px] ">
        <div className=" flex flex-col items-center w-full text-white font-light ">
          <h2 className=" text-center text-xl leading-snug lg:leading-[55px] text-white font-medium   font-spaceGrotesk ">
            Our Latest <span className=" text-green ">Projects</span>
          </h2>
          <div className=" z-10 relative h-auto w-full mt-[30px] md:mt-[30px] lg:mt-[40px] ">
            <Carousel
              responsive={responsive}
              draggable={true}
              autoPlay={true}
              rewind={true}
              rewindWithAnimation={true}
            >
              {images?.map((item) => {
                return (
                  <span key={item.id}>
                    <motion.div
                      variants={container}
                      initial="hidden"
                      whileInView="visible"
                      viewport={{ once: true, amount: 0.1 }}
                      key={item.id}
                    >
                      <ProjectCard item={item} />
                    </motion.div>
                  </span>
                );
              })}
            </Carousel>
          </div>
        </div>{" "}
      </div>
    </div>
  );
};

export default LatestProjects;
