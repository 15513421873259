import React from "react";
import Header from "../components/home/header";
import OurServices from "../components/home/ourservices";
import LatestProjects from "../components/home/latestprojects";
// import Featuring from "../components/home/featuring";
import AboutUs from "../components/home/aboutus";
import ValuedClients from "../components/home/valuedclients";
import ToolsAndPlatforms from "../components/home/toolsandplatforms";
import Testimonials from "../components/home/testimonials";
// import LatestBlogPosts from "../components/home/latestblogposts";
import WorkProcess from "../components/home/workprocess";

const Home = () => {
  return (
    <div>
      <Header />
      {/* <Featuring /> */}
      <AboutUs />
      <ValuedClients />
      <OurServices />
      <LatestProjects />
      <ToolsAndPlatforms />
      <Testimonials />
      {/* <LatestBlogPosts /> */}
      <WorkProcess />
    </div>
  );
};

export default Home;
